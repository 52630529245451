import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';

// wow writing react without webpack sure does suck!



// styled components
document.documentElement.style.setProperty("--color-bg", "#22262E");
document.documentElement.style.setProperty("--color-heading", "#798EB0");
document.documentElement.style.setProperty("--color-counter", "#8973FD");
document.documentElement.style.setProperty("--font-family-heading", "Mukta");



const targetDate = new Date(`Sep 28, 2022 14:00:00`).getTime();

const generateTimeDisplay = () => {
  const rightJustNow = new Date().getTime();
  const runway = targetDate - rightJustNow;
  const stateObj = {
    days: Math.floor(runway / (1000 * 60 * 60 * 24)),
    hours: Math.floor((runway % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((runway % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((runway % (1000 * 60)) / 1000)
  };
  return stateObj;
};

// components
const Counter = ({ displayValue, label }) => (
  <div className="CounterStyled">
    <h2>{label}</h2>
    {displayValue}
  </div>
);



function App() {

  const [timeDisplay, setTimeDisplay] = useState(
    generateTimeDisplay
  );

  const updateCounters = () => setTimeDisplay(generateTimeDisplay);

  
  useEffect(() => {
    setInterval(() => setTimeDisplay(generateTimeDisplay), 1000);
  }, []);
  
  return (
    <div className="App">
      <div className="info">
        <a href="mailto:info@wenshimmer.de">contact</a>
      </div>
      <header className="App-header">
      </header>
      <div className="ContainerStyled">
        <div className="WrapperStyled">
          <Counter displayValue={timeDisplay.days} label={"Days"} />
          <Counter displayValue={timeDisplay.hours} label={"Hours"} />
          <Counter displayValue={timeDisplay.minutes} label={"Minutes"} />
          <Counter displayValue={timeDisplay.seconds} label={"Seconds"} />
        </div>
      </div>

      <div className="Socialbar">
      <div className="SocialboxWrapper">
      <a target="_blank" href="https://twitter.com/shimmernet">
        <div className="Socialbox">
         <h2>Twitter</h2>
        </div>
        </a>
      </div>
      <div className="SocialboxWrapper">
      <a target="_blank" href="https://discord.gg/iota">
        <div className="Socialbox">
       <h2>Discord</h2>
        </div>
        </a>
      </div>
      <div className="SocialboxWrapper">
      <a target="_blank" href="https://t.me/Shimmer_DACH">
        <div className="Socialbox">
        <h2>Telegram</h2>
        </div>
        </a>
      </div>
      <div className="SocialboxWrapper">
      <a target="_blank" href="https://linktr.ee/shimmer_network">
        <div className="Socialbox">
        <h2>Network</h2>
        </div>
        </a>
      </div>
       
        
       
      </div>
      
    </div>
  );
}




export default App;
